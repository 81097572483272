import { throwError } from 'rxjs';

export abstract class BaseService {

  /**
   * Extract data from http response
   * @param response
   * @returns
   */
  protected extract(response: any) {
    return response || {};
  }
  /**
   * Extract data from http response from data property
   * @param respose
   * @returns
   */
  protected extractData(respose: any) {
    return respose.data || {};
  }
  /**
   * Handle errors in server communication
   * @param response
   * @returns
   */
  protected serviceError(response: Response | any) {
    let customError: string[] = [];
    if (response.statusText === 'Unknown Error') {
      customError.push('Ocorreu um erro desconhecido');
      response.error.errors = customError;
    }
    return throwError(response);
  }
}
