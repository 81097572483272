import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';


export class DealerGroupsTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return  [
      {
        anchorId: 'title.dealer.groups',
        route: '/dealer-groups',
        content: this.translateService.instant('Tour Message Content Dealer Groups List'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Dealer Groups'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.dealer.groups',
        route: '/dealer-groups',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Dealer Groups'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'actions.dealer.groups',
        route: '/dealer-groups',
        content: this.translateService.instant('Tour Message Content Details Dealer Groups'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Dealer Groups'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: true,
        isOptional: true,
        isAsync: true,
      },
      {
        anchorId: 'button.dealer.groups',
        route: '/dealer-groups',
        content: '',
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Create Dealer Groups'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'create.dealer.groups',
        route: '/dealer-groups/create',
        content: this.translateService.instant('Tour Message Content Create Dealer Groups'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Create Dealer Groups'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        disableScrollToAnchor: true,
      },
    ];
  }
}