import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';
export class CreateVisitTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'visit.country',
        route: '/visits/create#entrance',
        content:
        this.translateService.instant('Tour Message Content Create Visit Country'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'visit.plate',
        route: '/visits/create#entrance',
        content:
        this.translateService.instant('Tour Message Content Create Visit Plate'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'visit.plate.ocr',
        route: '/visits/create#entrance',
        content:
        this.translateService.instant('Tour Message Content Create Visit Plate Ocr'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'select.visit.type',
        route: '/visits/create#entrance',
        content:
        this.translateService.instant('Tour Message Content Create Visit Visit Type'),
        placement: 'top',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'register.visit',
        route: '/visits/create#entrance',
        content:
        this.translateService.instant('Tour Message Content Create Visit Register'),
        placement: 'top',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'details.visit',
        route: '/visits/create#entrance',
        content: this.translateService.instant('Tour Message Content Create Visit Details'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'photos.visit',
        route: '/visits/create#entrance',
        content: this.translateService.instant('Tour Message Content Create Visit Photos'),
        placement: 'top',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
    ];
  }
}