import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { I18nService } from "@app/i18n/service/i18n.service";
import { Observable } from "rxjs";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(public i18nService: I18nService){}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.i18nService.language){
      request = request.clone({ 
        setHeaders: {
          'Accept-Language': this.i18nService.language
        },
      });
    }
    
    
    return next.handle(request);
  }
}