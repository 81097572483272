import { Injectable, ErrorHandler } from '@angular/core';
import { MonitoringService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private monitoringService: MonitoringService) {
    super();
  }

  handleError(error: Error) {
    this.monitoringService.logException(error); 
  }
}
