import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class ReportTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'reports',
        content: this.translateService.instant('Tour Message Content Reports'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Reports'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'title.reports',
        content: this.translateService.instant('Tour Message Title Content Reports'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Reports'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
  }
}
