import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/core/auth';

@Injectable()
export class RoleInterceptor implements HttpInterceptor {
  constructor(public credentialsService: CredentialsService){}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(this.credentialsService.credentials === null){
      request = request.clone({ 
        setHeaders: {
          ignoreSelectedRole: 'true'
        },
      });
    }else if(this.credentialsService.credentials?.role !== null && this.credentialsService.credentials?.role != undefined){
      request = request.clone({ 
        setHeaders: {
          Role: this.credentialsService.credentials?.role?.toString() as string,
        },
      });
    }
    
    
    return next.handle(request);
  }
}
