import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { EndSessionRequest } from '@azure/msal-browser';
import { environment } from '@env/environment';
import { credentialsKey, CredentialsService } from '../../service/credentials.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    public msalService: MsalService,
    public credentialsService: CredentialsService
  ) { }

  ngOnInit(): void {
    this.logout();
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);

    const request: EndSessionRequest = {
      authority: environment.authority,
      account: this.msalService.instance.getActiveAccount(),                
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    }
    
    this.msalService.logout(request);
  }
}
