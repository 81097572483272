<header>
  <ng-alt-sidebar-container>
    <ng-alt-sidebar [(opened)]="_opened" id="sidebar" [closeOnClickOutside]="true" style="padding-top: 55px;">
      <div class="img bg-wrap text-center py-5 mt-3" style="background-image: url(/assets/img/bg_truck.jpg)">
        <div class="user-logo">
          <!-- <div *ngIf="displayDefaultImage" class="img" style="background-image: url(/assets/img/user.png);"></div>
                <div *ngIf="displayProfileImage" class="picProfile" id="picProfile"></div>
          <span class="profile-name">{{ this.credentialsService.credentials?.email }} </span> -->
          <!-- <div class="img" style="background-image: url(/assets/img/user.png);"></div> -->
          <!-- <div class="picProfile" id="picProfile"></div>
          <span class="profile-name">{{ this.credentialsService.credentials?.email }} </span> -->
        </div>
      </div>

      <ul id="menu" class="list-unstyled components mb-5">
        <li [hidden]="credentialsService.mustAccess([Role.Gate])">
          <a id="scheduled-vehicles" [routerLink]="['/visits/create']" [fragment]="'schedulings'"
            (click)="_toggleSidebar()">
            <span class="fa fa-calendar-alt mr-3"></span> {{ 'Scheduled Vehicles' | translate }}</a>
        </li>
        <li id="vehicle-entry" [hidden]="credentialsService.mustAccess([Role.Gate])">
          <a [routerLink]="['/visits/create']" [fragment]="'entrance'" (click)="_toggleSidebar()">
            <span class="fa fa-arrow-down mr-3"></span> {{ 'Vehicle Entry' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Gate])">
          <a [routerLink]="['/visits/create']" [fragment]="'exit'" (click)="_toggleSidebar()">
            <span class="fa fa-arrow-up mr-3"></span> {{ 'Vehicle Exit' | translate }}</a>
        </li>

        <li class="active" [hidden]="credentialsService.mustAccess([Role.Administrator])">
          <a [routerLink]="['/dealer-groups']" (click)="_toggleSidebar()">
            <span class="fas fa-vector-square mr-3"></span>
            {{ 'Economic Groups' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Administrator])">
          <a [routerLink]="['/dealers']" (click)="_toggleSidebar()">
            <span class="fas fa-handshake mr-3"></span> {{ 'Dealers' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Administrator, Role.Manager])">
          <a [routerLink]="['/users']" (click)="_toggleSidebar()">
            <span class="fas fa-users mr-3"></span> {{ 'Users' | translate }}</a>
        </li>

        <li [hidden]="credentialsService.mustAccess([Role.Administrator])">
          <a [routerLink]="['/visit-types']" (click)="_toggleSidebar()">
            <span class="fas fa-door-open mr-3"></span> {{ 'Visit Types' | translate }}</a>
        </li>

        <li [hidden]="credentialsService.mustAccess([Role.Administrator])">
          <a [routerLink]="['/reason-leaving']" (click)="_toggleSidebar()">
            <span class="fas fa-door-closed mr-3"></span> {{ 'Reason Leaving' | translate }}</a>
        </li>

        <li [hidden]="credentialsService.mustAccess([Role.Manager])">
          <a tourAnchor="configurations" style="cursor: pointer" (click)="_toggleConfigurations()">
            <span class="fas fa-cogs mr-3"></span> {{ 'Configurations' | translate }}</a>
          <ul class="list-unstyled components" [hidden]="_configurationsOpened">
            <li>
              <a [routerLink]="['/dealer-visit-types']" (click)="_toggleSidebar()">
                <span class="fas fa-door-open mr-3"></span> {{ 'Configure visits types' | translate }}</a>
            </li>
            <li>
              <a [routerLink]="['/visits/temporary-exits']" (click)="_toggleSidebar()">
                <span class="fas fa-warehouse mr-3"></span> {{ 'Dealer Configurations' | translate }}</a>
            </li>
          </ul>
        </li>

        <!-- <li [hidden]="credentialsService.mustAccess([Role.Manager])">
          <a [routerLink]="['/role-visit-types']" (click)="_toggleSidebar()">
            <span class="fas fa-users-cog mr-3"></span> {{ 'Configure role visits types' | translate }}</a
          >
        </li> -->
        <li [hidden]="credentialsService.mustAccess([Role.Manager])">
          <a [routerLink]="['/visits/logs']" (click)="_toggleSidebar()">
            <span class="fas fa-cog mr-3"></span> {{ 'Plate/Chassi Change Logs' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Manager, Role.Workshop])">
          <a [routerLink]="['/visits/photos']" (click)="_toggleSidebar()">
            <span class="fas fa-images mr-3"></span> {{ 'View Vehicle Photos' | translate }}</a>
        </li>

        <li [hidden]="credentialsService.mustAccess([Role.Workshop])">
          <a [routerLink]="['/visits']" (click)="_toggleSidebar()">
            <span class="fas fa-arrow-down mr-3"></span> {{ 'Vehicles in the workshop' | translate }}</a>
        </li>


        <li [hidden]="credentialsService.mustAccess([Role.Counter])">
          <a [routerLink]="['/visits']" (click)="_toggleSidebar()">
            <span class="fas fa-arrow-down mr-3"></span> {{ 'Vehicles in the cashier' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Manager, Role.Instructor])">
          <a [routerLink]="['/visits']" (click)="_toggleSidebar()">
            <span class="fas fa-warehouse mr-3"></span> {{ 'Vehicles in Dealer' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Gate])">
          <a [routerLink]="['/visits/dealer']" (click)="_toggleSidebar()">
            <span class="fas fa-warehouse mr-3"></span> {{ 'Vehicles in Dealer' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.CelulaAtiva])">
          <a [routerLink]="['/scheduling/customer-feedback']" (click)="_toggleSidebar()">
            <span class="fas fa-comments mr-3"></span> {{ 'Last Three Days Exits' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Manager])">
          <a [routerLink]="['/visits/group']" (click)="_toggleSidebar()">
            <span class="fas fa-house mr-3"></span> {{ 'Group Visits' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Manager, Role.Gate, Role.Workshop])">
          <a [routerLink]="['/visits/day-outs']" (click)="_toggleSidebar()">
            <span class="fas fa-eject mr-3"></span> {{ 'Day Outs' | translate }}</a>
        </li>

        <li [hidden]="shouldSeeTecnicalDelivery()">
          <a [routerLink]="['/technical-delivery']" (click)="_toggleSidebar()" (click)="_toggleTechnicalDelivery()">
            <span class="fas fa-truck mr-3"></span> {{ 'Technical Delivery' | translate }}</a>
          <a [routerLink]="['/technical-delivery/signed-deliveries']" (click)="_toggleSidebar()"
            (click)="_toggleTechnicalDelivery()">
            <span class="fas fa-pen-nib mr-3"></span> {{ 'Signed Deliveries' | translate }}</a>
          <a [routerLink]="['/technical-delivery/pending-signatures']" (click)="_toggleSidebar()"
            (click)="_toggleTechnicalDelivery()">
            <span class="fas fa-exclamation-circle mr-3"></span> {{ 'Pending Signatures' | translate }}</a>
          <ul class="list-unstyled components">
            <li [hidden]="shouldSeeTecnicalDeliveryConf()">
              <a style="cursor: pointer" (click)="_toggleTechnicalDeliveryConfigurations()">
                <span class="fas fa-cogs mr-3"></span> {{ 'Configurations' | translate }}</a>

              <ul class="list-unstyled components" [hidden]="_technicalDeliveryConfigurationsOpened">
                <li>
                  <a id="justificative-configuration"
                    [routerLink]="['/technical-delivery/configurations/justificatives']" (click)="_toggleSidebar()">
                    <span class="fas fa-file-signature mr-3"></span> {{ 'Justificative Configuration' | translate }}</a>
                </li>
                <li>
                  <a id="transport-configuration" [routerLink]="['/technical-delivery/configurations/segment']"
                    (click)="_toggleSidebar()">
                    <span class="fas fa-truck mr-3"></span> {{ 'Segment Configuration' | translate }}</a>
                </li>
                <li>
                  <a id="vehicle-class-configuration"
                    [routerLink]="['/technical-delivery/configurations/vehicle-classes']" (click)="_toggleSidebar()">
                    <span class="fas fa-tachometer mr-3"></span> {{ 'Vehicle Class Configuration' | translate }}</a>
                </li>
                <li>
                  <a id="checklist-configuration" [routerLink]="['/technical-delivery/configurations/checklists']"
                    (click)="_toggleSidebar()">
                    <span class="fas fa-list-check mr-3"></span> {{ 'Checklist' | translate }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li
          [hidden]="credentialsService.mustAccess([Role.Administrator, Role.Gate, Role.Workshop, Role.Counter, Role.Manager, Role.ReportViewer, Role.CelulaAtiva, Role.Instructor])">
          <a [routerLink]="['/visits/histories']" (click)="_toggleSidebar()">
            <span class="fas fa-clock-rotate-left mr-3"></span> {{ 'Visit Histories' | translate }}</a>
        </li>

        <li [hidden]="credentialsService.mustAccess([Role.Administrator, Role.ReportViewer, Role.Manager])">
          <a tourAnchor="reports" style="cursor: pointer" (click)="_toggleReports()">
            <span class="fas fa-tachometer-alt mr-3"></span> {{ 'Reports' | translate }}</a>
          <ul class="list-unstyled components" [hidden]="!_reportsOpened">
            <li *ngFor="let report of $reports | async">
              <a [routerLink]="['/reports', report.id]" (click)="selectReport()">
                <i class="far fa-chart-bar"></i> {{ report.name }}
              </a>
            </li>
          </ul>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.CelulaAtiva])">
          <a [routerLink]="['/scheduling']" (click)="_toggleSidebar()">
            <span class="fas fa-calendar-alt mr-3"></span> {{ 'Scheduled Vehicles' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Workshop, Role.Counter])">
          <a [routerLink]="['/scheduling/workshop']" (click)="_toggleSidebar()">
            <span class="fas fa-calendar-alt mr-3"></span> {{ 'Scheduled Vehicles' | translate }}</a>
        </li>
        <li [hidden]="credentialsService.mustAccess([Role.Monitor])">
          <a [routerLink]="['/monitor']" (click)="_toggleSidebar()">
            <span class="fas fa-tv mr-3"></span> {{ 'Monitor' | translate }}</a>
        </li>

        <!-- ONE VIEW -->
        <li [hidden]="shouldSeeOneView()">
          <a id="one-view" [routerLink]="['/workshop/one-view']" (click)="_toggleSidebar()">
            <span class="fas fa-desktop mr-3"></span> {{ 'One View' | translate }}</a>
        </li>
        <li>
          <a id="change-profile" [routerLink]="['/home']" (click)="_toggleSidebar()" [hidden]="!hasMultipleRoles"><span
              class="fa fa-id-card-alt mr-3"></span> {{ 'Change Profile' | translate }}
          </a>
        </li>
        <li>
          <a id="logout" [routerLink]="['/logout']" (click)="_toggleSidebar()"><span
              class="fa fa-sign-out-alt mr-3"></span> {{ 'Logout' | translate }}

          </a>
        </li>
      </ul>
    </ng-alt-sidebar>
    <div ng-alt-sidebar-content>
      <div class="navbar-wrapper" style="padding-top: 0%;">
        <nav [ngClass]="navbarClass" id="navbarClass">
          <div [ngClass]="navbarContainer" id="navbarContainer" [style.height.px]="this.smallScreen ? 70 : null">
            <div id="menu-toggle" [ngClass]="menuToggleClass"
              *ngIf="loginDisplay && credentialsService.credentials?.role !== Role.Monitor">
              <button tourAnchor="menu.step" class="navbar-toggler" type="button" (click)="_toggleSidebar()">☰</button>
            </div>
            <div class="dv-brand-title" id="brand-title-small-screen" *ngIf="this.smallScreen">
              <div>
                <div class="spread-mark">
                  <button id="home-button" class="btn" (click)="redirectToHome()">
                    <img alt="brand-logo" src="/assets/img/volvo_spread_mark.png" />
                  </button>
                </div>
                <b class="brand-title dealer-title"
                  [hidden]="!credentialsService.credentials?.dealerName && !credentialsService.credentials?.dealerGroupName">
                  {{ this.credentialsService.credentials?.dealerGroupName }},
                  {{this.credentialsService.credentials?.dealerName }}
                </b>
                <div *ngIf="credentialsService.credentials?.role === Role.Monitor && this.smallScreen">
                  <span><b>{{ time }}</b><b>{{ day | translate }}</b></span>
                </div>
              </div>
            </div>
            <div id="brand-title">
              <div *ngIf="credentialsService.credentials?.role !== Role.Monitor" style="vertical-align:bottom">
                <b *ngIf="this.smallScreen !== true">{{ credentialsService.displayName | async}}</b>
                <app-tour *ngIf="this.smallScreen !== true"></app-tour>
              </div>
              <div *ngIf="credentialsService.credentials?.role === Role.Monitor && this.smallScreen !== true"
                (click)="_toggleSidebar()">
                <h3 class="brand-title">
                  {{ this.credentialsService.credentials?.dealerGroupName }}
                </h3>
                <small> {{ this.credentialsService.credentials?.dealerName }}</small>
              </div>
            </div>
            <div [ngClass]="languageSelectorClass"
              *ngIf="credentialsService.credentials?.role !== Role.Monitor && this.smallScreen" id="language-selector">
              <app-language-selector></app-language-selector>
            </div>
          </div>
          <div *ngIf="this.smallScreen !== true">
            <div class="spread-mark centralized">
              <button id="home-button" class="btn" (click)="redirectToHome()">
                <img alt="brand-logo" src="/assets/img/volvo_spread_mark.png" />
              </button>
            </div>
          </div>
          <div *ngIf="credentialsService.credentials">
            <div *ngIf="credentialsService.credentials?.role !== Role.Monitor" [ngClass]="dealerTitleClass">
              <b *ngIf="this.smallScreen" style="padding-left: 20px;">{{ credentialsService.displayName | async}}</b>
              <app-tour *ngIf="this.smallScreen"></app-tour>
              <span [hidden]="credentialsService.mustHideLoggedRole|async"
                    [ngClass]="dealerTitleClass">
                <br *ngIf="this.smallScreen">
                <b class="brand-title dealer-title"
                  *ngIf="this.smallScreen !== true"
                  [hidden]="!credentialsService.credentials?.dealerName && !credentialsService.credentials?.dealerGroupName"
                  style="vertical-align: middle; padding-left: 20px;">
                  {{ this.credentialsService.credentials?.dealerGroupName }},
                  {{this.credentialsService.credentials?.dealerName }}
                </b>
                <small class="text-muted" style="vertical-align: middle; padding-left: 20px;">
                  {{credentialsService.getActualRole|async}}
                  (v.{{ environmentVersion }})</small>
              </span>
              <div [ngClass]="navbarContainer">
                <div [ngClass]="languageSelectorClass"
                  *ngIf="credentialsService.credentials?.role !== Role.Monitor && this.smallScreen !== true "
                  id="language-selector">
                  <app-language-selector></app-language-selector>
                </div>
              </div>
            </div>
            <div *ngIf="credentialsService.credentials?.role === Role.Monitor && this.smallScreen !== true">
              <span><b>{{ time }}</b><b>{{ day | translate }}</b></span>
            </div>
          </div>

        </nav>
        <div [ngClass]="environmentBanner" *ngIf="isTestingEnvironment()">
          <div [@openClose]="isTestEnvironmentMessageOpen ? 'open' : 'closed'">
            <p class="h2" translate>Testing Environment</p>
          </div>
        </div>
      </div>

      <main role="main" class="container-fluid">
        <ngx-spinner type="ball-clip-rotate" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
          [fullScreen]="true">
          <p style="color: white">{{'Loading'|translate}}</p>
        </ngx-spinner>
        <router-outlet></router-outlet>
      </main>
    </div>
  </ng-alt-sidebar-container>
</header>
<app-footer></app-footer>