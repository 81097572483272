<div class="container mt-n10" style="margin-top: 10%" *ngIf="roles.length > 1">
  <div class="row">
    <div class="col-12 mb-4">
      <div class="card h-100">
        <div class="card-body h-100 d-flex flex-column justify-content-center py-5 py-xl-4">
          <div class="row align-items-center">
            <div class="col-12">
              <ngx-spinner type="ball-clip-rotate" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" [fullScreen]="true">
                <p style="color: white">{{'Loading'|translate}}</p>
              </ngx-spinner>
              <div class="text-center">
                <h3 translate>Profile Select Message</h3>
              
                <button *ngFor="let role of roles" [id]="'btn-'+credentialsService.extractRoleName(role.role)|translate" type="button" (click)="selectRole(role.role)" class="btn btn-secondary select-role">
                  <i [id]="credentialsService.extractRoleName(role.role)|translate" [class]="credentialsService.extractRoleIcon(role.role)"></i>
                  
                  {{credentialsService.extractRoleName(role.role)|translate}}
                </button>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


