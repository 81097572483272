import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { PagedResponse } from '@app/services/griddatasource.service';
import { environment } from '@env/environment';
import { catchError, map, Observable } from 'rxjs';
import { VehicleCheckList, VehicleCheckListItem } from '../models/vehicle-checklist';
import { VehicleModel } from '../models/vehicle-model';

@Injectable({
  providedIn: 'root'
})

export class VehicleChecklistService  extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getVehicleCheckLists(filter: string, page: number, pageSize: number): Observable<PagedResponse<VehicleCheckList>> {
    return this.http
      .get<PagedResponse<VehicleCheckList>>(
        `${environment.technicalDeliveryServerUrl}/checklists?Page=${page}&PageSize=${pageSize}&Filter=${filter}`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  postVehicleCheckList(vehicleClass : VehicleCheckList) : Observable<VehicleCheckList>{
    return this.http
      .post<VehicleCheckList>(`${environment.technicalDeliveryServerUrl}/checklists`, vehicleClass)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  putVehicleCheckList(id: number, vehicleClass: VehicleCheckList) : Observable<VehicleCheckList>{
    return this.http
      .put<VehicleCheckList>(`${environment.technicalDeliveryServerUrl}/checklists/${id}`, vehicleClass)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleCheckList(id:any) : Observable<VehicleCheckList>{
    return this.http
      .get<VehicleCheckList>(`${environment.technicalDeliveryServerUrl}/checklists/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleCheckListByName(name:string) : Observable<PagedResponse<VehicleCheckList>>{
    return this.http
      .get<PagedResponse<VehicleCheckList>>(`${environment.technicalDeliveryServerUrl}/checklists?Filter=${name}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  deleteVehicleCheckList(id: number) : Observable<VehicleCheckList>{
    return this.http
      .delete<VehicleCheckList>(`${environment.technicalDeliveryServerUrl}/checklists/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleModels(): Observable<VehicleModel[]>{
    return this.http
      .get<VehicleModel[]>(`${environment.serverUrl}/vehicle-models`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleCheckListsItems(checkListId: number, filter: string, page: number, pageSize: number): Observable<PagedResponse<VehicleCheckListItem>> {
    return this.http
    .get<PagedResponse<VehicleCheckList>>(
      `${environment.technicalDeliveryServerUrl}/checklists/${checkListId}/checklist-items?Page=${page}&PageSize=${pageSize}&Filter=${filter}`
    )
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleCheckListsItem(checkListId: number, checklistItemId:number): Observable<VehicleCheckListItem> {
    return this.http
    .get<VehicleCheckListItem>(
      `${environment.technicalDeliveryServerUrl}/checklists/${checkListId}/checklist-items/${checklistItemId}`
    )
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  postVehicleCheckListItem(id: number, item : VehicleCheckListItem) : Observable<VehicleCheckList>{
    return this.http
      .post<VehicleCheckListItem>(`${environment.technicalDeliveryServerUrl}/checklists/${id}/checklist-items`, item)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  putVehicleCheckListItem(checkListId:number, id: number, vehicleChecklistItem: VehicleCheckListItem) : Observable<VehicleCheckListItem>{
    return this.http
      .put<VehicleCheckListItem>(`${environment.technicalDeliveryServerUrl}/checklists/${checkListId}/checklist-items/${id}`, vehicleChecklistItem)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  deleteVehicleCheckListItem(checkListId: number, itemId: number) : Observable<any>{
    return this.http
      .delete(`${environment.technicalDeliveryServerUrl}/checklists/${checkListId}/checklist-items/${itemId}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  postVehicleCheckListItemImage(checklistItemId: number, file: any) : Observable<VehicleCheckList>{
    return this.http
      .post<VehicleCheckListItem>(`${environment.technicalDeliveryServerUrl}/checklist-items/${checklistItemId}/photos`, file)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleCheckListItemImage(checklistItemId: number) : Observable<VehicleCheckListItem>{
    return this.http
      .get<VehicleCheckListItem>(`${environment.technicalDeliveryServerUrl}/checklist-items/${checklistItemId}/photos`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  putVehicleCheckListItemImage(checklistItemId:number, file:any) : Observable<VehicleCheckListItem>{
    return this.http
      .put<VehicleCheckListItem>(`${environment.technicalDeliveryServerUrl}/checklist-items/${checklistItemId}/photos`, file)
      .pipe(map(super.extract), catchError(super.serviceError));
  }
}
