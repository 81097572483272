import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/auth';
import { RoleEnum } from '@app/core/auth/enum/role.enum';
import { Report } from '@app/modules/reports/models/report.model';
import { ReportService } from '@app/modules/reports/services/report.service';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { TourService } from 'ngx-ui-tour-ng-bootstrap';
import { lastValueFrom, Observable, of } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { environment } from '@env/environment';
import { Environments } from '@env/environments';
import { Credentials } from '@app/core/auth/models/credentials.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DealersEnum } from '../../enum/dealers.enum';
import { DwellAuthScopes, GraphAuthScopes } from '@app/core/auth/configs/auth-config';
moment.locale('en-US');
import jwt_decode from 'jwt-decode';
import { TranslateService } from '@ngx-translate/core';
import { StepChangeParams } from 'ngx-ui-tour-core';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        backgroundColor: 'red',
        color: 'white',
        margin: '2px 2px 2px 2px'
      })),
      state('closed', style({
        backgroundColor: 'yellow',
        color: 'black',
        margin: '2px 2px 2px 2px'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class MenuComponent implements OnInit, AfterViewInit {
  isTestEnvironmentMessageOpen = true;
  version = environment.version;
  environmentVersion = environment.version;
  roles: any[];

  public Role = RoleEnum;
  public isCollapsed: boolean;
  _opened: boolean = false;
  _reportsOpened: boolean = false;
  _configurationsOpened: boolean = false;
  _technicalDeliveryConfigurationsOpened: boolean = false;
  _technicalDeliveryOpened: boolean = false;

  _VehicleWorkshop: boolean = true;

  loginDisplay = false;
  profile?: Credentials;
  userRole: number;
  hasMultipleRoles: boolean = false;

  time: string = '';
  day: string = '';
  versionProd: string = '';

  $profile: Observable<Credentials>;
  $reports: Observable<Report[]>;
  screenWidth: number;
  navbarContainer: string;
  navbarClass: string;
  environmentBanner: string;
  dealerTitleClass: string;
  menuToggleClass: string;
  languageSelectorClass: string;
  smallScreen: boolean = false;
  

  constructor(
    public _msalService: MsalService,
    public credentialsService: CredentialsService,
    public reportService: ReportService,
    public translateService: TranslateService,
    private router: Router,
    private tourService: TourService,
    private spinner: NgxSpinnerService,
  ) {
    this.isCollapsed = true;
    this.screenWidth = window.innerWidth;
  }

  toggleTestEnvironmentMessage() {
    this.isTestEnvironmentMessageOpen = !this.isTestEnvironmentMessageOpen;
  }

  shouldSeeOneView(): boolean{
    return this.credentialsService.mustAccess([RoleEnum.Workshop, RoleEnum.OneView]) && !this.credentialsService.credentials?.preferences?.singleScreenEnable
  }

  shouldSeeTecnicalDelivery(){

    return (this.credentialsService.mustAccess([RoleEnum.Instructor]) === false)? false : true;
  }

  shouldSeeTecnicalDeliveryConf(){

    return ((this.credentialsService.credentials?.dealerId != DealersEnum.Volvo) === false)? false : true;
  }

  ngAfterViewInit(): void {

  }

  checkIfHasMultipleRoles(){
    this.$profile.subscribe(
      (profile: Credentials) =>{
        this.hasMultipleRoles = (profile.roles as []).length > 1
    })
  }

  redirectToHome() {
    this.credentialsService.validateRedirect(100).then((url) => {
      this.router.navigateByUrl(url);
    });
  }
  selectReport() {
    this._toggleSidebar();
    this._toggleReports();
  }

  ngOnInit() {
    this.setProfile();
    this.currentTourStep();
    this.liveClock();
    this.getPowerBIReports();
    this.handleTestingEnvironmentMessage();
    this.updatenavbarContainer();
  }

  isTestingEnvironment():boolean{
    return environment.name === Environments.Testing || environment.name === Environments.Homolog|| environment.name === Environments.Development;
  }
  handleTestingEnvironmentMessage() {
    let self = this;
    setInterval(function(){
      if(self.isTestingEnvironment()){
        self.toggleTestEnvironmentMessage()
      }
    }, 1000);
  }

  currentTourStep() {
    this.tourService.stepShow$.subscribe((params: StepChangeParams) => {
      if (params.step.anchorId === 'reports' || params.step.anchorId === 'menu.step') {
        this._toggleSidebar();
        this._toggleReports();
        this._toggleConfigurations();
      } else {
        this._opened = false;
        this._reportsOpened = false;
        this._configurationsOpened = false;
      }
    });
  }


  liveClock() {
    let self = this;
    window.setInterval(function () {
      moment.locale('en-US');
      var getTime = moment()
        .utcOffset(self.credentialsService.credentials?.timezone as number)
        .format(('DD/MM/y H:mm:ss').concat(";" + 'dddd'));
      var timeDay = getTime.split(';');
      self.time = timeDay[0] + " ";
      self.day = timeDay[1];

    }, 1000);


  }

  getPowerBIReports() {
    this.credentialsService.getProfile().subscribe(profile => {
      this.profile = profile;
      if (
        profile.role == RoleEnum.ReportViewer ||
        profile.role == RoleEnum.Administrator ||
        profile.role == RoleEnum.Manager
      ) {
        this.$reports = this.reportService.getReportsMenu();
      }
    });

  }

  _toggleReports() {
    this.getPowerBIReports();

    this._reportsOpened = !this._reportsOpened;
  }

  _toggleConfigurations() {
    this._configurationsOpened = !this._configurationsOpened;
  }

  _toggleTechnicalDeliveryConfigurations(){
    this._technicalDeliveryConfigurationsOpened = !this._technicalDeliveryConfigurationsOpened;
  }

  _toggleTechnicalDelivery(){
    this._technicalDeliveryOpened = !this._technicalDeliveryOpened;
  }

  setProfile() {
    this.$profile = this.credentialsService.getProfile();
    this.loginDisplay = this._msalService.instance.getAllAccounts().length > 0;
    this._msalService.instance.setActiveAccount(this._msalService.instance.getAllAccounts()[0]);
    this.getAccessToken();
  }

  async getAccessToken(){
    this.spinner.show()
    let account = this._msalService.instance.getAllAccounts()[0];
    this.loginDisplay = this._msalService.instance.getAllAccounts().length > 0;

    const graphAccessTokenRequest = {
      scopes: GraphAuthScopes(),
      account: account,
      authority: environment.authority
    };

    const graphToken = await this._msalService.instance.acquireTokenSilent(graphAccessTokenRequest);

    const dwellAccessTokenRequest = {
      scopes: DwellAuthScopes(),
      account: account,
      authority: environment.authority
    };


    const token = await this._msalService.instance.acquireTokenSilent(dwellAccessTokenRequest);
    const profile = await lastValueFrom(this.$profile);
    const claims = jwt_decode(token.accessToken) as any;
    this.credentialsService.displayName = of(claims.claimusermail || claims.upn);
    let credentials = {
      ...profile,
      email: claims.claimusermail,
      graph_access_token: graphToken.accessToken,
      api_access_token: token.accessToken,
    }

    this.credentialsService.setCredentials(credentials, true);
    this.spinner.hide();
    this.checkIfHasMultipleRoles();

  }

  _toggleSidebar() {
    this._opened = !this._opened;
    this._configurationsOpened = !this._configurationsOpened;
    this._technicalDeliveryOpened = !this._technicalDeliveryOpened;
    this._technicalDeliveryConfigurationsOpened = !this._technicalDeliveryConfigurationsOpened;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenWidth = (event.target as Window).innerWidth;
    this.updatenavbarContainer();
  }

  updatenavbarContainer(): void {
    if(this.screenWidth < 360){
      this.navbarContainer = 'nav navbar-nav flex-row fixed-top justify-content-space-between';
      this.navbarClass = 'navbar navbar-light bg-light navbar-semi-light bg-white navbar-shadow small-screen-class';
      this.environmentBanner = 'small-screen-banner';
      this.menuToggleClass = 'menu-toggle-small dv-menu-toggle';
      this.dealerTitleClass = '';
      this.languageSelectorClass = 'language-selector-small dv-language-selector';
      this.smallScreen = true;
    }
    else if (this.screenWidth <= 1024) {
      this.navbarContainer = 'nav navbar-nav flex-row fixed-top justify-content-space-between';
      this.navbarClass = 'navbar navbar-light bg-light navbar-semi-light bg-white navbar-shadow small-screen-class';
      this.environmentBanner = 'small-screen-banner';
      this.menuToggleClass = 'menu-toggle dv-menu-toggle';
      this.dealerTitleClass = '';
      this.languageSelectorClass = 'language-selector dv-language-selector';
      this.smallScreen = true;
    } else {
      this.navbarContainer = 'nav navbar-nav flex-row justify-content-space-between';
      this.navbarClass = 'navbar navbar-light bg-light fixed-top navbar-semi-light bg-white navbar-shadow large-screen-class';
      this.environmentBanner = 'large-screen-banner';
      this.menuToggleClass = 'menu-toggle';
      this.languageSelectorClass = 'language-selector';
      this.dealerTitleClass = 'dealer-title';
      this.smallScreen = false;
    }
  }
}
