import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contract-accepted',
  templateUrl: './contract-accepted.component.html',
  styleUrls: ['./contract-accepted.component.scss']
})
export class ContractAcceptedComponent implements OnInit, OnDestroy {

  constructor(

    public translateService: TranslateService,
    protected injector: Injector,
  ) {}
  ngOnInit(): void {
    document.body.classList.add('contract-accepted-body');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('contract-accepted-body');
  }
}
