<div class="login-container bg-light">
  <div class="login-box">
    <div>
      <!-- <div class="d-inline-block ml-3">
        <app-language-selector></app-language-selector>
      </div> -->
    </div>
    <div class="container">
      <div class="card col-sm-9 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mt-3 mb-4 mx-auto">
        <div class="card-header border-0">
          <div class="card-title text-center">
            <div class="p-1">
              <img src="../../../../assets/img/volvo_logo_80x80.png" alt="Volvo logo" />
            </div>
            <app-language-selector></app-language-selector>
          </div>
          <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
            <span translate>APP_NAME</span>
          </h6>
        </div>
        <div class="card-body">
        
          <form [formGroup]="loginForm" novalidate>
            
            <div class="m-3">
              <button class="btn btn-primary w-100" type="button" (click)="msalLogin()">
                <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                <span translate>Login</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
