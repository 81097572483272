import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class VisitTypesTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.visit-types',
        route: '/visit-types',
        content: this.translateService.instant('Tour Message Content Visit Types'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Visit Types'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.visit-types',
        route: '/visit-types',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Filter Visit Types'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'actions.visit-types',
        route: '/visit-types',
        content: this.translateService.instant('Tour Message Content Edit Visit Types'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Visit Types'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: true,
        isOptional: true,
        isAsync: true,
      },
      {
        anchorId: 'button.visit-types',
        route: '/visit-types',
        content: '',
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title New Visit Types'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'create.visit-types',
        route: '/visit-types/create',
        content: this.translateService.instant('Tour Message Content New Visit Types'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title New Visit Types'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        disableScrollToAnchor: true,
      },
    ];
  }
}