import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';
export class VisitsTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'filter.visits',
        route: '/visits/create#exit',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'release.workshop',
        route: '/visits/create#exit',
        content: this.translateService.instant('Tour Message Content Release Visit'),
        placement: 'left',
        title: this.translateService.instant('Tour Message Title Release Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
    ];
  }
}