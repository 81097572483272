<div [hidden]="hideModal" id="content" #content>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Checklist' | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <input [checked]="Response<0" [disabled]="true" type="checkbox" id="client-checklist">
    <label for="client-checklist">{{'Customer will not proceed with the technical delivery checklist' | translate}}</label>
    <div class="table-responsive-md">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" sortable="item" width="10px">{{ 'Item' | translate }}</th>
            <th scope="col" sortable="Descricao">{{ 'Description' | translate }}</th>
            <th scope="col" sortable="Image">{{ 'Image' | translate }}</th>
            <th scope="col" sortable="">{{ '#' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let checklist of CheckItem; index as i">
            <td class="item-index">
              <ngb-highlight [result]="(i+1).toString()" [term]="''"></ngb-highlight>
            </td>
            <td>
              <ngb-highlight [result]="checklist.description" [term]="''"></ngb-highlight>
            </td>
            <td>
              <button
                [hidden]="checklist.url === null"
                (click)="openImage(checklist)"
                translate
              >
                <i [ngbTooltip]="'Item Image'|translate" placement="right" class="fa-solid fa-images"></i>
                {{'Item Image'|translate}}
            </button>
            </td>
            <td>
              <input [checked]="checklist.marked" [disabled]="true" class="checkbox-item" id="checklistcheckbox" type="checkbox">
            </td>
          </tr>
          <tr [hidden]="CheckItem !== undefined">
            <td colspan="6">{{'No Data' | translate}}</td>
          </tr>
        </tbody>
      </table>
      <input [checked]="Response>0" [disabled]="true" type="checkbox" id="checklist-finish">
      <label for="checklist-finish">{{'All items have been checked off by the customer' | translate}}</label>
    </div>
  </div>
  <div class="modal-footer checklist-footer d-flex justify-content-between">
    <button id="print-page-button" type="button" class="btn btn-primary print-page-button" (click)="printPage()" translate>Print</button>
  </div>
</div>

