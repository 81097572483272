<div class="container mt-10" style="margin-top: 10%" *ngIf="loginDisplay">
  <div class="row justify-content-center">
    <div class="col-xxl-8 col-xl-12 mb-4">
      <div class="card h-100">
        <div class="card-body h-100 d-flex flex-column justify-content-center py-5 py-xl-4">
          <div class="row align-items-center">
            <div class="col-xl-8 col-xxl-12">
              <div class="text-center text-xl-left text-xxl-center px-4 mb-4 mb-xl-0 mb-xxl-4">
                <h1
                  class="text-primary"
                  style="font-family: 'Volvo Novum Regular' !important; color: #0d3896 !important"
                  translate
                >
                  <span translate>APP_NAME</span>
                </h1>
                <p class="text-gray-700 mb-0" style="color: #464855 !important" translate>Home Text</p>
              </div>
            </div>
            <div class="col-xl-4 col-xxl-12 text-center">
              <img class="img-fluid" src="/assets/img/at-work.JPG" style="position: relative;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
