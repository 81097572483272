import { Routes, Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { MenuComponent } from '../component/menu/menu.component';

/**
 * Provides helper methods to create routes.
 */
export class Navegation {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: MenuComponent,
      children: routes,
      canActivate: [MsalGuard],
    };
  }

}
