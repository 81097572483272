import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Report, EmbedReport } from '../models/report.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getReportsMenu(): Observable<Report[]> {
    return this.http
      .get<Report[]>(`${environment.serverUrl}/PowerBI/reports/menu`, { headers: new HttpHeaders().append('ignoreSelectedRole', 'true') })
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getReports(): Observable<Report[]> {
    return this.http
      .get<Report[]>(`${environment.serverUrl}/PowerBI/reports`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getReport(id: any): Observable<EmbedReport> {
    return this.http
      .get<EmbedReport>(`${environment.serverUrl}/PowerBI/reports/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }
}
