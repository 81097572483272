export enum RoleEnum {
  Administrator = 1,
  Gate = 2,
  Workshop = 3,
  Counter = 4,
  Manager = 5,
  ReportViewer = 6,
  CelulaAtiva = 7,
  Instructor = 8,
  Monitor = 9,
  Integration = 10,
  OneView= 11
}
