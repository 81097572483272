import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class WorkshopSchedulingTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      /**
       * Schedulings
       */
      {
        anchorId: 'title.scheduling.workshop',
        route: '/scheduling/workshop',
        content: this.translateService.instant('Tour Message Content Scheduled Visits'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Scheduled Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'filter.scheduling.workshop',
        route: '/scheduling/workshop',
        content:
          this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'date.range.filter',
        route: '/scheduling/workshop',
        content:
          this.translateService.instant('Tour Message Content Date Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Date Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
    ];
  }
}