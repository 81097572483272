import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Logger, UntilDestroy, untilDestroyed } from '@app/shared';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  InteractionStatus,
  InteractionType,
  RedirectRequest
} from '@azure/msal-browser';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  viewPassword: boolean = false;

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinner: NgxSpinnerService  ) {
  }

  ngOnInit() {
    sessionStorage.clear();
    this.initMsal();
  }

  initMsal() {
    this.spinner.show();
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {

      });

    this.spinner.hide();
  }

  msalLogin() {
    this.isLoading = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      if (this.msalGuardConfig.authRequest) {
        this.isLoading = false;
        const redirectRequest = { ...this.msalGuardConfig.authRequest, redirectStartPage: environment.redirectUrl } as RedirectRequest;
        this.msalService.loginRedirect(redirectRequest)
      } else {
        this.isLoading = false;
        this.msalService.loginRedirect()
      }
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {},
        (error: any) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
