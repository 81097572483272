import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class UsersTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.users',
        route: '/users',
        content: this.translateService.instant('Tour Message Content Users'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.users',
        route: '/users',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'actions.users',
        route: '/users',
        content: this.translateService.instant('Tour Message Content Action Users'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: true,
        isOptional: true,
        isAsync: true,
      },
      {
        anchorId: 'button.users',
        route: '/users',
        content: '',
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Create Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'create.users',
        route: '/users/create',
        content: this.translateService.instant('Tour Message Content Create Users'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Create Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        disableScrollToAnchor: true,
      },
    ];
  }
}


export class ManagerUsersTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.users',
        route: '/users',
        content: this.translateService.instant('Tour Message Content Users'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.users',
        route: '/users',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'actions.users',
        route: '/users',
        content: this.translateService.instant('Tour Message Content Action Users'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Users'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: true,
        isOptional: true,
        isAsync: true,
      }
    ];
  }
}