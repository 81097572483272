import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
})
export class ImageComponent implements OnInit {
  @Input() vehicleSide: string;
  @Input() image: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
