import { Directive, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Directive()
export abstract class BaseFormComponent implements OnInit {
  form!: FormGroup;
  currentAction: string;
  submittedForm: boolean = false;
  protected route: ActivatedRoute;
  protected router: Router;
  protected formBuilder: FormBuilder;

  constructor(@Inject(Injector) protected injector: Injector) {
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.formBuilder = this.injector.get(FormBuilder);
  }

  ngOnInit(): void {
    this.setCurrentAction();
    this.buildForm();
    this.loadForm();
  }

  protected setCurrentAction() {
    if (this.route.snapshot.url[0].path == 'create') {
      this.currentAction = 'create';
    } else {
      this.currentAction = 'edit';
    }
  }

  protected isCreationView(): boolean{
    if (this.currentAction == 'edit') {
      return false;
    }

    return true;
  }

  protected abstract loadForm(): void;
  protected abstract buildForm(): void;
  abstract submitForm(): void;
}
