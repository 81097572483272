import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';
import { INgbStepOption } from 'ngx-ui-tour-ng-bootstrap/lib/step-option.interface';
import { CreateVisitTour } from './create.visit.steps';
import { VisitsTour } from './visits.steps';

export class GateSchedulingTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'gate.schedulings',
        route: '/visits/create',
        content: this.translateService.instant('Tour Message Content Gate Schedulings'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Gate Schedulings'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.gate.schedulings',
        route: '/visits/create',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Gate Schedulings'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'list.gate.schedulings',
        route: '/visits/create',
        content: this.translateService.instant('Tour Message Content Entry Schedulings'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Entry Schedulings'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
    ];
  }
}


export class GateTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    let createVisitTour = new CreateVisitTour(this.translateService);
    let schedulingSteps = new GateSchedulingTour(this.translateService);
    let visitSteps = new VisitsTour(this.translateService);
    let gateSteps: INgbStepOption[] = schedulingSteps.steps().concat(
      {
        anchorId: 'gate.entries',
        route: '/visits/create',
        content: this.translateService.instant('Tour Message Content Entry Visit'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Entry Visit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      createVisitTour.steps(),
      {
        anchorId: 'gate.exits',
        route: '/visits/create',
        content: this.translateService.instant('Tour Message Title Gate Release'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Release'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      visitSteps.steps()
    );
    return gateSteps;
  }
}