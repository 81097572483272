// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { LogLevel } from '@azure/msal-browser';
import { env } from './.env';

export const environment = {
  name: 'development',
  production: true,
  version: env.npm_package_version,
  serverUrl: 'https://dwell-app-dev.azurewebsites.net',
  technicalDeliveryServerUrl:'https://dwell-et-dev.azurewebsites.net',
  clientId: 'da440f4f-09bd-4db8-88c6-245be690f087',
  authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  redirectUrl: window.location.origin,
  postLogoutRedirectUri: `${window.location.origin}/#/login`,
  instrumentationKey: '52b5ce37-9b9e-4196-906f-4daa9a2ce4ef',
  defaultLanguage: 'pt-BR',
  supportedLanguages: ['pt-BR' , 'en-US', 'es-ES'],
  gdspApiKey: 'be4cd2bf507b45419ca91e44eb2707cf',
  gdspApiUrl: 'https://api-qa.gdsp.volvo.com/api',
  msalLogLevel: LogLevel.Error,
  msGraphUrl: 'https://graph.microsoft.com/v1.0/me'
};
