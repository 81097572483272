import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class OneViewTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.oneview',
        route: '/workshop/one-view',
        content: this.translateService.instant('Tour Message Content One View'),
        placement: 'bottom-before',
        title: this.translateService.instant('Tour Message Title Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'search.oneview',
        route: '/workshop/one-view',
        content: this.translateService.instant('Tour Message Content One View'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      
      {
        anchorId: 'recent.search.oneview',
        route: '/workshop/one-view',
        content: this.translateService.instant('Tour Message Content One View Recent'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      
    ]
  }
}