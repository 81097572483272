import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '../service/i18n.service';

const flags: Record<string, string> = {
  'pt-BR': '../../assets/img/flags/br.svg',
  'en-US': '../../assets/img/flags/us.svg',
  'es-ES': '../../assets/img/flags/es.svg',
};

export interface Lang {
  code: string;
  flag: string;
}
@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() inNavbar = false;
  @Input() menuClass = '';
  public supportedLanguages: Lang[] = [];

  constructor(private i18nService: I18nService) {}

  ngOnInit() {
    this.i18nService.supportedLanguages.forEach((language) => {
      this.supportedLanguages.push({
        code: language,
        flag: flags[language],
      });
    });
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return flags[this.i18nService.language];
  }

  get languages(): Lang[] {
    return this.supportedLanguages;
  }
}
