import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';
export class DealersTour{
  constructor(public translateService: TranslateService){}
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.dealers',
        route: '/dealers',
        content: this.translateService.instant('Tour Message Content Dealers'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Dealers'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.dealers',
        route: '/dealers',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Dealers'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'actions.dealers',
        route: '/dealers',
        content: this.translateService.instant('Tour Message Content Dealers Actions'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Dealers'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: true,
        isOptional: true,
        isAsync: true,
      },

      {
        anchorId: 'create.dealers',
        route: '/dealers/create',
        content: this.translateService.instant('Tour Message Content Dealers Create'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Dealers Create'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        disableScrollToAnchor: true,
      },
    ]
  };
}