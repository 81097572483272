import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger } from '../services/logger.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MsalService } from '@azure/msal-angular';
import { DwellAuthScopes } from '@app/core/auth/configs/auth-config';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    public toastrService: ToastrService,
    public translateService: TranslateService,
    public _msalService: MsalService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      log.error('Request error', response);
    }

    if (response instanceof HttpErrorResponse) {
      if (response.status === 400) {
        throw response;
      }

      if (response.status === 404) {
        throw response;
      }

      if (response.status === 403) {
        this.acquireTokenAndRedirect();
      }

      if (response.status === 401) {
        localStorage.clear();
        window.location.href = '/#/login';
      }

      if (response.status === 422) {
        var errorMessage = response.error.Details[0].Erros[0];
        var translatedMessage = this.translateService.instant(errorMessage);
        this.toastrService.error(translatedMessage);
        throw response;
      }
    }
    return of(response);
  }

  acquireTokenAndRedirect() {
    const dwellAccessTokenRequest = {
      scopes: DwellAuthScopes(),
      account: this._msalService.instance.getAllAccounts()[0],
      authority: environment.authority,
    };
    this._msalService.instance.acquireTokenSilent(dwellAccessTokenRequest).then(
      () => {
        window.location.href = '/#/home';
      },
      () => {
        window.location.href = '/#/unauthorized';
      }
    );
  }
}
