import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/core/auth';
import { RoleEnum } from '@app/core/auth/enum/role.enum';
import { IStepOption, TourService } from 'ngx-ui-tour-ng-bootstrap';
import { DealerGroupsTour } from '@app/modules/dealer-groups/components/tour-steps/dealer-group.steps';
import { DealersTour } from '@app/modules/dealers/components/tour-steps/dealer.steps';
import { ReportTour } from '@app/modules/reports/components/tour-steps/reports.steps';
import { SchedulingTour } from '@app/modules/scheduling/components/tour-steps/scheduling.steps';
import { UsersTour } from '@app/modules/users/components/tour-steps/users.steps';
import { VisitTypesTour } from '@app/modules/visit-types/components/tour-steps/visit-types.steps';
import { CounterTour } from '@app/modules/visits/components/tour-steps/counter.steps';
import { GateTour } from '@app/modules/visits/components/tour-steps/gate.steps';
import { InstructorTour } from '@app/modules/visits/components/tour-steps/instructor.steps';
import { ManagerTour } from '@app/modules/visits/components/tour-steps/manager.steps';
import { WorkshopSchedulingTour } from '@app/modules/visits/components/tour-steps/workshop-schedulings.steps';
import { WorkShopTour } from '@app/modules/visits/components/tour-steps/workshop.steps';
import { OneViewTour } from '@app/modules/workshop/tour-steps/one-view.steps';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
})
export class TourComponent implements OnInit {
  constructor(private credentialService: CredentialsService, public tourService: TourService, public translateService: TranslateService) {}
  administratorSteps: IStepOption[]
  workshopSteps: IStepOption[]
  instructorSteps: IStepOption[]
  counterSteps: IStepOption[]
  managerSteps: IStepOption[]
  reportSteps: IStepOption[]
  schedulingSteps: IStepOption[]
  gateSteps: IStepOption[]

  dealerGroups: DealerGroupsTour;
  dealers: DealersTour;
  users: UsersTour;
  visitTypes: VisitTypesTour;
  report: ReportTour;
  oneView: OneViewTour;
  workshopSchedulings: WorkshopSchedulingTour;
  counter: CounterTour;
  scheduling: SchedulingTour;
  gate: GateTour;
  manager: ManagerTour;
  instructor: InstructorTour;


  ngOnInit(): void {
    this.initializeSteps();
    this.setupRoleSteps();
    this.refreshOnLangChange();
  }

  refreshOnLangChange(){
    this.translateService.onLangChange.subscribe(res => {
        this.initializeSteps();
        this.setupRoleSteps();
        var statusTour = this.tourService.getStatus();
        this.tourService.end();
        if(statusTour === 1){
          this.startTour();
        }

    });
  }

  setupRoleSteps() {
    let workshopTourSteps = new WorkShopTour(this.translateService)
    this.administratorSteps = this.administratorSteps.concat(
      this.dealerGroups.steps(),
      this.dealers.steps(),
      this.users.steps(),
      this.visitTypes.steps(),
      this.report.steps()
    );
    this.workshopSteps = this.workshopSteps.concat(workshopTourSteps.steps(), this.oneView.steps(), this.workshopSchedulings.steps());
    this.reportSteps = this.reportSteps.concat(this.report.steps());
    this.counterSteps = this.counterSteps.concat(this.counter.steps());
    this.schedulingSteps = this.schedulingSteps.concat(this.scheduling.steps());
    this.gateSteps = this.gateSteps.concat(this.gate.steps());
    this.managerSteps = this.managerSteps.concat(this.manager.steps());
    this.instructorSteps = this.instructorSteps.concat(this.instructor.steps());
  }

  initializeSteps(){
    this.administratorSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Administrator')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.workshopSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Workshop')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.instructorSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Instructor')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.counterSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Counter')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.managerSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Manager')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.reportSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Reports')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.schedulingSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Scheduling')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.gateSteps = [
      {
        anchorId: 'start.tour',
        content: this.translateService.instant('Tour Message Start',{ value:this.translateService.instant('Gate')}),
        placement: 'bottom-after',
        title: this.translateService.instant('Welcome'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'menu.step',
        content: this.translateService.instant('Tour Message Menu'),
        placement: 'bottom',
        title: this.translateService.instant('Navigation'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
    this.dealerGroups = new DealerGroupsTour(this.translateService);
    this.dealers = new DealersTour(this.translateService);
    this.users = new UsersTour(this.translateService);
    this.visitTypes = new VisitTypesTour(this.translateService);
    this.report = new ReportTour(this.translateService);
    this.oneView = new OneViewTour(this.translateService);
    this.workshopSchedulings = new WorkshopSchedulingTour(this.translateService);
    this.counter = new CounterTour(this.translateService);
    this.scheduling = new SchedulingTour(this.translateService);
    this.gate = new GateTour(this.translateService);
    this.manager = new ManagerTour(this.translateService);
    this.instructor = new InstructorTour(this.translateService);

  }

  startTour() {

    switch (this.credentialService.credentials?.role) {
      case RoleEnum.Administrator:
        this.initAdministratorTour();
        break;
      case RoleEnum.Manager:
        this.initManagerTour();
        break;
      case RoleEnum.Gate:
        this.initGateTour();
        break;
      case RoleEnum.Counter:
        this.initCounterTour();
        break;
      case RoleEnum.Workshop:
        this.initWorkshopTour();
        break;
      case RoleEnum.ReportViewer:
        this.initReportViewerTour();
        break;
      case RoleEnum.CelulaAtiva:
        this.initCelulaAtivaTour();
        break;
      case RoleEnum.Instructor:
        this.initInstructorTour();
        break;
      case RoleEnum.OneView:

      default:
        break;
    }
  }

  initInstructorTour() {
    this.tourService.initialize(this.instructorSteps);
    this.tourService.start();
  }

  initAdministratorTour() {
    this.tourService.initialize(this.administratorSteps);
    this.tourService.start();
  }
  initManagerTour() {
    this.tourService.initialize(this.managerSteps);
    this.tourService.start();
  }
  initGateTour() {
    this.tourService.initialize(this.gateSteps);
    this.tourService.start();
  }
  initCounterTour() {
    this.tourService.initialize(this.counterSteps);
    this.tourService.start();
  }
  initWorkshopTour() {
    this.tourService.initialize(this.workshopSteps);
    this.tourService.start();
  }
  initReportViewerTour() {
    this.tourService.initialize(this.reportSteps);
    this.tourService.start();
  }
  initCelulaAtivaTour() {
    this.tourService.initialize(this.schedulingSteps);
    this.tourService.start();
  }
}
