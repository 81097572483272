import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { HomeComponent } from './core/navegation/component/home/home.component';
import { NotFoundComponent } from './core/navegation/component/not-found/not-found.component';
import { UnauthorizedComponent } from './core/navegation/component/unauthorized/unauthorized.component';
import { Navegation } from './core/navegation/service/navegation.service';
import { AcceptContractComponent } from './modules/technical-delivery/components/deliveries/accept-contract/accept-contract.component';
import { ContractAcceptedComponent } from './modules/technical-delivery/components/deliveries/accept-contract/contract-accepted/contract-accepted.component';

const routes: Routes = [
  Navegation.childRoutes([
    {
      path: 'technical-delivery',
      loadChildren: () => import('./modules/technical-delivery/technical-delivery.module').then((m) => m.TechnicalDeliveryModule),
    },
    {
      path: 'workshop',
      loadChildren: () => import('./modules/workshop/workshop.module').then((m) => m.WorkshopModule),
    },
    {
      path: 'reports',
      loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    },
    {
      path: 'dealer-groups',
      loadChildren: () => import('./modules/dealer-groups/dealer-groups.module').then((m) => m.DealerGroupsModule),
    },
    { path: 'dealers', loadChildren: () => import('./modules/dealers/dealers.module').then((m) => m.DealersModule) },
    { path: 'users', loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule) },
    {
      path: 'visit-types',
      loadChildren: () => import('./modules/visit-types/visit-types.module').then((m) => m.VisitTypesModule),
    },
    {
      path: 'reason-leaving', loadChildren: () => import('./modules/reason-leaving/reason-leaving.module').then((m) => m.ReasonLeavingModule),
    },
    {
      path: 'dealer-visit-types',
      loadChildren: () =>
        import('./modules/dealer-visit-types/dealer-visit-types.module').then((m) => m.DealerVisitTypesModule),
    },
    { path: 'visits', loadChildren: () => import('./modules/visits/visits.module').then((m) => m.VisitsModule) },
    {
      path: 'scheduling',
      loadChildren: () => import('./modules/scheduling/scheduling.module').then((m) => m.SchedulingModule),
    },
    { path: 'unauthorized', component: UnauthorizedComponent },

    { path: 'code', redirectTo: '/home', pathMatch: 'full' },
    { path: 'error', redirectTo: '/home', pathMatch: 'full' },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, data: { title: marker('Select Profile') } },
    {
      path: 'monitor',
      loadChildren: () => import('./modules/monitor/monitor.module').then((m) => m.MonitorModule),
    },
  ]),

  { path: 'technical-delivery/accept-contract/:id', component: AcceptContractComponent, data: { title: marker('Accept Contract') }, },
  { path: 'technical-delivery/contract-accepted/:id', component: ContractAcceptedComponent, data: { title: marker('Contract Accepted') }},

  // Fallback when no prior route is matched
  { path: '**', component: NotFoundComponent },
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
    })
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
