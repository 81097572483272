<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>{{ vehicleSide | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img [src]="image" class="d-block w-100" [alt]="vehicleSide|translate" />
        </div>
      </div>
    </div>
  </div>
</div>
