import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class SchedulingTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.scheduling',
        route: '/scheduling',
        content: this.translateService.instant('Tour Message Content Schedulings Create'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Gate Schedulings'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'filter.visits',
        route: '/scheduling',
        content: this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Gate Schedulings'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
      {
        anchorId: 'date.range.filter',
        route: '/scheduling',
        content: this.translateService.instant('Tour Message Content Date Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Date Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
      {
        anchorId: 'actions.scheduling',
        route: '/scheduling',
        content: this.translateService.instant('Tour Message Content Schedulings Actions'),
        placement: 'left',
        title: this.translateService.instant('Tour Message Title Actions Schedulings'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
        isAsync: true,
      },
      {
        anchorId: 'edit.scheduling',
        route: `/scheduling/edit/${localStorage.getItem('schedulingId')}`,
        content: this.translateService.instant('Tour Message Content Schedulings Edit'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Schedulings Edit'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
      {
        anchorId: 'details.scheduling',
        route: `/scheduling/details/${localStorage.getItem('schedulingId')}`,
        content: this.translateService.instant('Tour Message Content Schedulings Details'),
        placement: 'right',
        title: this.translateService.instant('Tour Message Title Schedulings Details'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },

      {
        anchorId: 'button.scheduling',
        route: '/scheduling',
        content: this.translateService.instant('Tour Message Content Schedulings Create Button'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Schedulings Create'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
      },
    ];
  }
}