<div [ngClass]="{ 'nav-item': inNavbar }" ngbDropdown display="dynamic" placement="bottom-end">
  <a *ngIf="inNavbar; else button" id="language-dropdown" class="nav-link" ngbDropdownToggle>
    {{ currentLanguage | translate }}
  </a>
  <ng-template #button>
    <button id="language-selector" placement="left" [ngbTooltip]="'Select Language'|translate" id="language-dropdown" class="btn btn-sm btn-light" ngbDropdownToggle>
      <img class="flag img-fluid" [src]="currentLanguage" />
    </button>
  </ng-template>
  <div ngbDropdownMenu aria-labelledby="language-dropdown" [ngClass]="menuClass">
    <button [id]="language.code" class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language.code)">
      <img [ngbTooltip]="language.code|translate" placement="right" class="flag img-fluid" [src]="language.flag" [alt]="language.code|translate"/>
    </button>
  </div>
</div>
