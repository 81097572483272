import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

export class WorkShopTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      /**
       * Workshop
       */
      {
        anchorId: 'title.visits',
        route: '/visits',
        content: this.translateService.instant('Tour Message Content Visits'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'filter.visits',
        route: '/visits',
        content:
          this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'date.range.filter',
        route: '/visits',
        content:
          this.translateService.instant('Tour Message Content Date Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Date Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
      {
        anchorId: 'actions.visits',
        route: '/visits',
        content:
          this.translateService.instant('Tour Message Content Actions'),
        placement: 'top',
        title: this.translateService.instant('Tour Message Title Actions'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
      {
        anchorId: 'edit.visits',
        route: `/visits/edit/${localStorage.getItem('visitId')}`,
        content:
          this.translateService.instant('Tour Message Content Edit Visits'),
        placement: 'bottom-before',
        title: this.translateService.instant('Tour Message Title Edit Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
    ];
  }

}